<template>
  <div class="areaAppoint">
    <div class="tips">
      <img src="./img/message.png" alt="" />
      <div>如遇法定节假日，请先与管理员确认场地是否开放</div>
    </div>
    <div class="commonVenues" v-if="this.commomPlaceList.length > 0">
      常用场馆
    </div>
    <div class="commonVenuesBox" v-if="this.commomPlaceList.length > 0">
      <div
        class="itemBox"
        v-for="(item, index) in commomPlaceList"
        :key="index"
        @click="toDetail(item)"
      >
        <div class="imgBox">
          <img :src="item.picUrl" alt="" />
        </div>
        <div class="name">{{ item.name }}</div>
      </div>
    </div>
    <div class="areaAppoint-t">
      <div class="search">
        <div class="search-c" @click="showType">
          <div class="item-l">{{ requestData.type }}</div>
          <div class="item-r">
            <img
              :class="['arrow', { downArrow: pickShow }]"
              src="./img/downarrow.png"
              alt=""
            />
          </div>
        </div>
        <div class="search-l" @click="showPlaceType">
          <div class="item-l">{{ requestData.placeName }}</div>
          <div class="item-r">
            <img
              :class="['arrow', { downArrow: placeTypeShow }]"
              src="./img/downarrow.png"
              alt=""
            />
          </div>
        </div>

        <div class="search-r" @click="showPeople">
          <div class="item-l">{{ requestData.num }}</div>
          <div class="item-r">
            <img
              :class="['arrow', { downArrow: peopleShow }]"
              src="./img/downarrow.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="oList">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        :immediateCheck="false"
        ref="list"
        :finishedText="finishedText"
      >
        <div
          v-for="(item, index) in listData"
          :key="index"
          class="areaAppointItem"
          @click="toDetail(item)"
        >
          <div class="areaAppointItem-t">
            <div class="areaAppointItem-tl">
              <img :src="item.picUrl" alt="" />
            </div>
            <div class="areaAppointItem-tr">
              <div class="name">{{ item.name }}</div>
              <div class="num">
                <div class="num-l">{{ item.area }}㎡</div>
                |
                <div class="num-r">{{ item.count }}人</div>
              </div>
              <div class="type">会议室</div>
            </div>
          </div>
          <div class="areaAppointItem-br">立即预定</div>
          <div class="historyBox">
            <div class="box">
              <img src="./img/history.png" alt="" />
              <div class="num">{{ item.totalDegree }}次</div>
            </div>
          </div>
        </div>
      </v-list>
    </div>
    <!-- 场地类型 -->
    <v-picker
      :columns="columns"
      :valueShow="pickShow"
      :valueKey="valueKey"
      @clickOverlay="clickPickOverlay"
      @cancel="cancelPick"
      @confirmPicker="confirmPick"
    ></v-picker>
    <!-- 区县市区类型 -->
    <v-picker
      :columns="placeTypeColumns"
      :valueShow="placeTypeShow"
      :valueKey="placeValueKey"
      @clickOverlay="closePlaceType"
      @change="change"
      @cancel="cancelPickPlaceType"
      @confirmPicker="confirmPickPlaceType"
    ></v-picker>
    <!-- 人数类型 -->
    <v-picker
      :columns="peopleColumns"
      :valueShow="peopleShow"
      :valueKey="peopleKey"
      @clickOverlay="clickPeopleOverlay"
      @cancel="cancelPeoplePick"
      @confirmPicker="confirmPeople"
    ></v-picker>
    <!-- 日期选择 -->
    <!-- <v-dateTimePicker :valueShow="show" v-model="valueDate" @clickOverlay="clickOverlay" @cancel="cancel" @confirmPicker="confirmPicker"></v-dateTimePicker> -->
    <img src="./img/myReserve.png" class="toMy" @click="toMy" alt="" />
  </div>
</template>

<script>
import {
  getAreaListUrl,
  getAreaTypeUrl,
  showCommonPlaceUrl,
  getStreetCommunityUrl,
} from "./api.js";
import { peopleRange } from "./map.js";
import { gloabalCount } from "@/utils/common";
var moment = require("moment");
export default {
  name: "venueAppoint",
  data() {
    return {
      finishedText: "没有更多了",
      peopleKey: "text",
      valueKey: "typeName",
      placeValueKey: "text",
      columns: [
        {
          typeName: "全部",
          id: "",
        },
      ],
      peopleColumns: peopleRange,
      pickShow: false,
      valueDate: new Date(),
      placeTypeShow: false, //区县
      placeTypeColumns: [
        {
          text: "五江口社区",
          districtName: "五江口社区",
          districtCode: "",
          children: [
            {
              text: "五江口社区",
              streetName: "五江口社区",
              streetId: "",
              children: [
                {
                  text: "五江口社区",
                  structuringName: "五江口社区",
                  structuringId: "",
                },
              ],
            },
          ],
        },
      ],
      show: false,
      peopleShow: false,
      finished: false,
      loading: false,
      level: null,
      requestData: {
        curPage: 1,
        pageSize: 10,
        date: moment(new Date()).format("YYYY-MM-DD"),
        type: "类型",
        typeId: "",
        num: "人数",

        districtCode: "", //区域编码
        streetId: "", //街道id
        structuringId: "", //社区id

        placeName: "地点",

        minCount: "", //最小容纳人数
        maxCount: "", //最大容纳人数
      },
      listData: [],
      commomPlaceList: [],
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  watch: {
    userId: {
      handler(newValue, oldValue) {
        if (newValue && newValue !== "0") {
          this.onLoad();
          this.getCommomPlaceList();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    // this.getPlaceList();
  },
  mounted() {
    // this.getActivityList();
    this.getAreaTypeList();
    gloabalCount("", 5, 1);
    console.log(this.userId);
    // if (this.userId != "0") {
    //   this.getCommomPlaceList();
    // }
  },
  methods: {
    toMy() {
      this.$router.push({
        name: "myAppointList",
      });
    },
    change(picker, values, index) {
      console.log(picker, values, index, 2002);
      // if (index == 0) {
      //     picker.setColumnValues(2, values[0].children[0].children || []);
      // }
      picker.setColumnValues(index + 1, values[index].children || []);
    },
    getPlaceList() {
      //获得区县/街道/社区
      this.$axios.get(`${getStreetCommunityUrl}`).then((res) => {
        if (res.code === 200) {
          let demo2 = {
            streetId: "",
            streetName: "全部",
            text: "全部",
            children: [
              {
                structuringName: "全部",
                text: "全部",
                structuringId: "",
              },
            ],
          };
          let demo3 = {
            structuringName: "全部",
            structuringId: "",
          };
          res.data.forEach((item, index) => {
            //循环区

            item.list.unshift(demo2);
            (item.text = item.districtName),
              (item.children = item.list),
              item.children.forEach((item2, index2) => {
                //循环街道
                if (item2.streetName == "全部") {
                  console.log(123);
                } else if (item2.streetName != "全部") {
                  item2.list.unshift(demo3);
                  item2.text = item2.streetName;
                  item2.children = item2.list;

                  item2.children.forEach((item3, index3) => {
                    //循环社区

                    item3.text = item3.structuringName;
                  });
                }
              });
          });
          let list = res.data;
          list.forEach((item) => {
            this.placeTypeColumns.push(item);
          });
          console.log(this.placeTypeColumns);
        }
      });
    },
    arrFormat(list) {
      if (list[0] == "全部" && list[1] == "全部" && list[2] == "全部") {
        return "全部";
      }
      if (list[0] != "全部" && list[1] == "全部") {
        return list[0];
      }
      if (list[0] != "全部" && list[1] != "全部" && list[2] == "全部") {
        return list[1];
      }
      if (list[0] != "全部" && list[1] != "全部" && list[2] != "全部") {
        return list[2];
      }
    },
    confirmPickPlaceType(value) {
      //选择区县社区确认按钮
      console.log(value);
      // let name = value[value.length - 1]
      let name1 = value[0];
      let name2 = value[1];
      let name3 = value[2];
      // if (name1 == "全部" && name2 == "全部" && name3 == "全部") {
      this.placeTypeShow = false;
      // this.requestData.placeName = this.arrFormat(value);
      this.requestData.placeName = "五江口社区";
      this.requestData.structuringId = "";
      this.requestData.streetId = "";
      this.requestData.districtCode = "";
      this.init();
      // } else {
      //   this.placeTypeShow = false;
      //   this.requestData.placeName = this.arrFormat(value);
      //   this.placeTypeColumns.forEach((item, index) => {
      //     if (item.text == name1) {
      //       this.requestData.districtCode = item.districtCode;
      //       item.children.forEach((item2, index2) => {
      //         if (item2.streetName == name2) {
      //           this.requestData.streetId = item2.streetId;
      //         }
      //         item2.children.forEach((item3, index3) => {
      //           if (item3.structuringName == name3) {
      //             this.requestData.structuringId = item3.structuringId;
      //           }
      //         });
      //       });
      //     }
      //   });
      //   this.init();
      // }
    },
    getCommomPlaceList() {
      // 获取常用场馆展示列表
      let params = {
        userId: this.userId,
        // userId: '1435123978587934721'
      };
      this.$axios
        .get(`${showCommonPlaceUrl}`, {
          params: params,
        })
        .then((res) => {
          if (res.code === 200) {
            this.commomPlaceList = res.data;
          } else {
          }
        });
    },
    showPlaceType() {
      //展示区县社区
      this.placeTypeShow = true;
    },
    closePlaceType() {
      //关闭区县社区
      this.placeTypeShow = false;
    },
    cancelPickPlaceType() {
      this.placeTypeShow = false;
    },

    // 人数
    showPeople() {
      this.peopleShow = true;
    },
    cancelPeoplePick() {
      this.peopleShow = false;
    },
    init() {
      this.requestData.curPage = 1;
      this.listData = [];
      this.$refs.list._data.loading = true;
      this.finished = false;
      this.getActivityList();
    },
    confirmPick(value) {
      this.pickShow = false;
      this.requestData.type = value.typeName;
      this.requestData.typeId = value.id;
      this.init();
    },
    cancelPick() {
      this.pickShow = false;
    },
    clickPeopleOverlay() {
      this.peopleShow = false;
    },
    clickPickOverlay() {
      this.pickShow = false;
    },
    confirmPeople(value) {
      console.log(value);
      this.peopleShow = false;
      this.requestData.num = value.text;
      this.requestData.minCount = value.minCount;
      this.requestData.maxCount = value.maxCount;
      this.init();
    },
    confirmPicker(value) {
      this.show = false;
      this.requestData.date = moment(value).format("YYYY-MM-DD");
      this.init();
    },
    cancel() {
      this.show = false;
    },
    clickOverlay() {
      this.show = false;
    },
    showType() {
      this.pickShow = true;
    },
    showDate() {
      this.show = true;
    },
    onLoad() {
      this.getActivityList();
    },
    getAreaTypeList() {
      this.$axios.get(`${getAreaTypeUrl}`).then((res) => {
        if (res.code === 200) {
          res.data.forEach((item) => {
            this.columns.push(item);
          });
        } else {
        }
      });
    },
    getActivityList() {
      let params = {
        tenantId: this.tenantId,
        houseId: this.houseId || this.communityId || undefined,
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        date: this.requestData.date,
        minCount: this.requestData.minCount,
        maxCount: this.requestData.maxCount,
        typeId: this.requestData.typeId,
        // districtCode: this.requestData.districtCode,
        // streetId: this.requestData.streetId,
        // structuringId: this.requestData.structuringId,
        districtCode: "330212",
        streetId: "330212015000",
        structuringId: "330212015002",
      };
      this.$axios
        .get(`${getAreaListUrl}`, {
          params,
        })
        .then((res) => {
          if (res.code === 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            res.data.records.forEach((item) => {
              if (item.picUrl && item.picUrl.length > 0) {
                item.picUrl = this.$handleImg(400, 400, item.picUrl);
              }
              this.listData.push(item);
            });
          } else {
          }
        });
    },
    toDetail(item) {
      this.$router.push({
        name: "venueDetail",
        query: {
          placeId: item.id,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.areaAppoint {
  box-sizing: border-box;
  min-height: 100vh;
  background: #f7f7f7;
  .toMy {
    width: 128px;
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
  }
  .commonVenuesBox {
    height: 186px;
    display: flex;
    padding: 0 132px;
    // align-items: center;
    text-align: center;

    .itemBox {
      flex: 1;

      .imgBox {
        width: 108px;
        height: 106px;
        border-radius: 50%;
        margin: 0 auto;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .name {
        font-size: 26px;
        font-weight: 400;
        color: #1b1a21;
        margin-top: 14px;
      }
    }
  }

  .tips {
    font-size: 24px;
    font-weight: 400;
    color: #5e5aff;
    height: 88px;
    line-height: 88px;
    background: #f4f5ff;
    display: flex;
    align-items: center;

    img {
      width: 38px;
      height: 36px;
      margin-left: 36px;
      margin-right: 16px;
    }
  }

  .commonVenues {
    height: 66px;
    font-size: 24px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    line-height: 66px;
    margin-left: 36px;
  }

  .downArrow {
    transform: rotate(180deg);
  }

  .areaAppoint-t {
    background: #fff;
    padding: 0;
    margin: 0 28px;
    box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.49);
    border-radius: 16px;
  }

  .search {
    display: flex;
    justify-content: space-between;
    padding: 26px 32px;
  }

  .search-l {
    display: flex;
    align-items: center;

    .item-l {
      font-size: 30px;
      font-weight: 600;
      color: #5e5aff;
    }

    .item-r {
      width: 30px;
      font-size: 0;
      height: 30px;
      margin-left: 10px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .search-c {
    display: flex;
    align-items: center;

    .item-l {
      font-size: 30px;
      font-weight: 600;
      color: #5e5aff;
    }

    .item-r {
      width: 30px;
      height: 30px;
      font-size: 0;
      margin-left: 10px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .search-r {
    display: flex;
    align-items: center;

    .item-l {
      font-size: 30px;
      font-weight: 600;
      color: #5e5aff;
    }

    .item-r {
      width: 30px;
      font-size: 0;
      margin-left: 10px;
      height: 30px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .oList {
    padding: 0 40px;
  }

  .areaAppointItem {
    height: 220px;
    background: #fff;
    border-radius: 20px;
    margin: 30px 0;
    box-shadow: 0px 0px 50px 4px #f7f7f7;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;

    .historyBox {
      position: absolute;
      top: 50px;
      right: 32px;
      color: rgba(0, 0, 0, 0.5);
      font-size: 24px;

      .box {
        display: flex;
        align-items: center;

        img {
          width: 28px;
          height: 24px;
          margin-right: 4px;
        }
      }
    }

    .areaAppointItem-t {
      display: flex;
      height: 220px;
      box-sizing: border-box;

      .areaAppointItem-tl {
        width: 224px;
        height: 100%;
        margin-right: 22px;
        box-sizing: border-box;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .areaAppointItem-tr {
        height: 100%;
        flex: 1;
        font-size: 30px;
        box-sizing: border-box;
        position: relative;

        .name {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          width: 300px;
          font-size: 32px;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.85);
          box-sizing: border-box;
          position: absolute;
          left: 0;
          top: 38px;
        }

        .num {
          display: flex;
          color: rgba(0, 0, 0, 0.5);
          position: absolute;
          top: 90px;
          left: 0;

          .num-l {
            margin-right: 10px;
          }

          .num-r {
            margin-left: 10px;
          }
        }

        .type {
          color: rgba(0, 0, 0, 0.5);
          color: #999;
          position: absolute;
          left: 0;
          bottom: 14px;
        }
      }
    }

    .areaAppointItem-br {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 194px;
      height: 62px;
      background: linear-gradient(180deg, #9793ff 0%, #5e5aff 100%);
      color: #fff;
      text-align: center;
      font-size: 26px;
      line-height: 62px;
      border-radius: 16px 0px 16px 0px;
    }
  }
}
</style>
